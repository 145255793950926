@tailwind base;
@tailwind components;
@tailwind utilities;

h1 {
  @apply text-2xl font-bold my-2;
}

h2 {
  @apply text-xl font-semibold my-2;
}

h3 {
  @apply text-lg font-medium my-2;
}

h4 {
  @apply text-lg font-medium my-1;
}

h5 {
  @apply text-lg font-medium my-1;
}

h6 {
  @apply text-xs font-medium my-1;
}

html,
body,
#root {
  min-height: 100%;
  min-width: 390px;
  margin: 0;
  padding: 0;
}

body {
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Roboto", sans-serif;
}
