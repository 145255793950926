@use "sass:math";

$label-height: 30px;
$label-width-ratio: math.div(500, 200); // Использование math.div для деления
$label-width: $label-height * $label-width-ratio;
$toggle-radius: $label-height; // Так как высота и ширина изначально равны, радиус равен высоте
$after-size: math.div($label-height * 9, 10); // 90% от высоты label
$icon-size: math.div($label-height * 8, 10); // 80% от высоты label
$icon-offset: math.div($label-height * 2, 10); // 20% от высоты label

.dark_mode_toggle_wrapper {
  position: relative;
  
  input {
    visibility: hidden;
    height: 0;
    width: 0;
    position: relative;
    display: none;
  }
  
  label {
    width: $label-width;
    height: $label-height;
    position: relative;
    display: block;
    border-radius: $toggle-radius;
    cursor: pointer;
    transition: 0.3s;

    &:after {
      content: "";
      width: 26px;
      height: 26px;
      position: absolute;
      top: 5%; // Отступ сверху изменён на процент от высоты
      left: 4%; // Для сохранения пропорций
      background: linear-gradient(180deg, #ffcc89, #d8860b);
      border-radius: $after-size;
      box-shadow: 0 5px 10px rgba(0,0,0,0.2);
      transition: 0.3s;
    }
    svg {
      position: absolute;
      width: $icon-size;
      top: 3px;
      z-index: 100;

      &.sun {
        left: 3px;
        color: #282828;
        transition: 0.3s;
      }

      &.moon {
        right: 5px;
        color: #7e7e7e;
        transition: 0.3s;
      }
    }

    span {
        font-size: .65rem; // Пример размера шрифта
        position: relative;
        user-select: none; // Запретить выделение текста
    &.sun {
        left: 33px;
        color: #282828; // Пример цвета шрифта
    }
    &.moon {
        left: 10px;
        color: #fff; // Пример цвета шрифта
    }
    }
  }

  input:checked + label {

    &:after {
      left: auto;
      right: 5%;
      transform: translateX(0%);
      background: linear-gradient(180deg, #777, #3a3a3a);
    }

    svg.sun {
      color: #666666;
    }

    svg.moon {
      color: transparent;
      fill: #ffcc89;
    }
  }


  label:active:after {
    width: math.div($after-size * 14, 10); // Расширение на 40% от изначального размера
  }

}
