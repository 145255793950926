  
  @keyframes pulse {
    0%, 100% { opacity: 0.1; }
    50% { opacity: 1; }
  }
  @keyframes rotate {
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
  }

  .svgLogo {
    display: block;
    animation: rotate 20s linear infinite;
    height: 100%; /* Адаптируйте высоту к родительскому элементу */
    width: auto; /* Поддерживайте аспект-рейшо SVG */
    
    path {
      animation: pulse 1.5s infinite;
      fill: #40c456; // Задаём начальный цвет, который будет изменяться в .darkTheme
  
      &:nth-child(1) { animation-delay: -0.125s; }
      &:nth-child(2) { animation-delay: -0.25s; }
      &:nth-child(3) { animation-delay: -0.375s; }
      &:nth-child(4) { animation-delay: -0.5s; }
      &:nth-child(5) { animation-delay: -0.625s; }
      &:nth-child(6) { animation-delay: -0.75s; }
      &:nth-child(7) { animation-delay: -0.875s; }
      &:nth-child(8) { animation-delay: -1s; }
      &:nth-child(9) { animation-delay: 0.3s; }
      // Продолжите шаблон для каждого path
    }
  }
  
  .svgLogo path {
    fill: rgb(50, 84, 84); // Цвет для темной темы
  }

  .darkTheme .svgLogo path {
    fill: cyan; // Цвет для темной темы
  }
  