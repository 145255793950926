
  .svgText {
    height: 100%; /* Адаптируйте высоту к родительскому элементу */
    width: auto; /* Поддерживайте аспект-рейшо SVG */

    path {
      fill: rgb(50, 84, 84); // Цвет для темной темы
    }
  }

  .darkTheme .svgText path {
    fill: cyan; // Цвет для темной темы
  }