.languageSwitcher {
    position: relative;
    height: 30px;
    margin-left: 10px;
    button {
        border-radius: 30px;
        transition: 0.3s;
        height: 30px;
        .iconwrapper{
            &:after {
                content: "";
                width: 22px;
                height: 22px;
                position: absolute;
                top: 5px; // Отступ сверху изменён на процент от высоты
                left: 5px; // Для сохранения пропорций
                background: linear-gradient(135deg, rgba(255,255,255,0.33) 0%,rgba(255,255,255,0.33) 60%,rgba(255,255,255,0) 61%,rgba(255,255,255,0) 100%);
                border-radius: 50%;
                box-shadow: 0 5px 10px rgba(0,0,0,0.2);
                transition: 0.3s;
              }
        }
        svg {
            transition: 0.3s;
            border-radius: 27px;
            position: relative;
        }
        span {
            font-size: .8rem;
        }
    }
  }
  